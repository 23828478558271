import { default as React } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { AboutContainer, GalleryContainer, HeroContainer, PortfolioContainer, ServiceContainer } from "@containers";
import { HeaderLayout, FooterLayout, Layout } from "@layouts";
import { getMarkdownNodeBySlug, getPageItemByScope, getSetting, hasPageItemByScope } from "@definitions/utils";


const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query IndexArtistAndAuthorQuery {
      allMarkdown: allMarkdownRemark(
        filter: {frontmatter: {scope: {eq: "index-artist-and-author"}}}
      ) {
        edges {
          node {
            id
            frontmatter {
              author
              categories
              date(formatString: "LLL")
              scope
              slug
              title
            }
            html
          }
        }
      }

      footer: layout(layoutMetadata: {scope: {eq: FOOTER}}) {
        ...Footer
      }

      header: layout(layoutMetadata: {scope: {eq: HEADER}}) {
        ...Header
      }

      page: page(
        pageMetadata: {scope: {eq: "index-artist-and-author"}}
      ) {
        ...Page
      }

      router: layout(layoutMetadata: {scope: {eq: ROUTER}}) {
        ...Router
      }

      site: site {
        ...Site
      }
    }
`);

  return (
    <Layout
      title={getSetting(data.page?.pageSettings, "title")}
      className="white-version"
    >
      {(data.header && data.router) && (
        <HeaderLayout
          data={{
            header: data.header,
            router: data.router,
            socials: data.site?.siteMetadata?.socials
          }}
          className="rn-d-none"
        />
      )}
        <main className="main-page-wrapper">
          {hasPageItemByScope(data.page?.items, "hero") && (
            <HeroContainer
              id="hero"
              data={getPageItemByScope(data.page?.items, "hero")}
            />
          )}

          {hasPageItemByScope(data.page?.items, "service") && (
            <ServiceContainer
              data={getPageItemByScope(data.page?.items, "service")}
            />
          )}

          {hasPageItemByScope(data.page?.items, "about-me") && (
            <AboutContainer
              id="about-me"
              data={{
                item: getPageItemByScope(data.page?.items, "about-me"),
                node: getMarkdownNodeBySlug(data.allMarkdown?.edges, "about-me")
              }}
            />
          )}

          {hasPageItemByScope(data.page?.items, "books") && (
            <PortfolioContainer
              id="books"
              data={{
                item: getPageItemByScope(data.page?.items, "books"),
                node: getMarkdownNodeBySlug(data.allMarkdown?.edges, "books")
              }}
            />
          )}

          {hasPageItemByScope(data.page?.items, "inspirations") && (
            <PortfolioContainer
              id="inspirations"
              data={{
                item: getPageItemByScope(data.page?.items, "inspirations"),
                node: getMarkdownNodeBySlug(data.allMarkdown?.edges, "inspirations")
              }}
            />
          )}

          {hasPageItemByScope(data.page?.items, "gallery") && (
            <GalleryContainer
              id="galleries"
              data={{
                item: getPageItemByScope(data.page?.items, "gallery")
              }}
              isLightbox={false}
            />
          )}

          {hasPageItemByScope(data.page?.items, "poetry") && (
            <PortfolioContainer
              id="poetry"
              data={{
                item: getPageItemByScope(data.page?.items, "poetry"),
                node: getMarkdownNodeBySlug(data.allMarkdown?.edges, "poetry")
              }}
            />
          )}
        </main>
      {(data.footer && data.footer) && (
        <FooterLayout
          data={{
            header: data.header,
            footer: data.footer
          }}
          className="section-separator"
        />
      )}
    </Layout>
  );
}


export default IndexPage;
